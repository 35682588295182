import React, {useCallback, useContext, useEffect} from "react";
import AppContext from "../../services/AppProvider";
import APIservices from "../../services/APIservices";

export default function Logout(props) {
    const {updateContext} = useContext(AppContext);

    let handleOnLoad = useCallback(async () => {
        const response = await APIservices.logout()
        if (response.data.status === true) {
            window.localStorage.removeItem('app_data');
            updateContext({
                isAuthenticated: false,
                token: null,
                user: null,
            });
        } else {
            window.localStorage.removeItem('app_data');
            updateContext({
                isAuthenticated: false,
                token: null,
                user: null,
            });
        }
    }, [updateContext]);

    useEffect(() => {
        handleOnLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div/>
    );
}
import React, {useContext} from "react";
import {scaleRotate as Menu} from "react-burger-menu";
import {Link} from "react-router-dom";
import AppContext from "../../services/AppProvider";

export default function SideBar(props) {
    const {context} = useContext(AppContext);
    return (
        <Menu {...props}>
            {<Link className="menu-item" to="/">Home</Link>}
            {<Link className="menu-item" to="/converter/string">String Converter</Link>}
            {<Link className="menu-item" to="/formatter/json">Json Formatter</Link>}
            {<Link className="menu-item" to="/converter/base64">Base64 Converter</Link>}
            {<Link className="menu-item" to="/converter/timezone">Time Zone Converter</Link>}
            {<Link className="menu-item" to="/converter/postman-to-openapi">Postman To OpenApi</Link>}
            {context.isAuthenticated && <Link className="menu-item" to="/logout">Logout</Link>}
            {!context.isAuthenticated && <Link className="menu-item" to="/login">Login</Link>}
        </Menu>
    );
}
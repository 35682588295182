import React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import JsonImage from "../../assets/json.png"
import StringCaseImage from "../../assets/string_case.png"
import Base64Image from "../../assets/base64.png"
import PostmanToOpenApi from "../../assets/open-api-postman.png"


export default function Home(props) {
    return (
        <div>
            <Row style={{paddingTop: '5%'}}>
                <Col>
                    <Card style={{width: '18rem', height: '27.5rem'}}>
                        <Card.Img className="card-img-block" variant="top" src={JsonImage}/>
                        <Card.Body>
                            <Card.Title>JSON Formatter</Card.Title>
                            <Card.Text>
                                To convert a JSON String to a Beautified / Formatter JSON.
                            </Card.Text>
                            <Button href='/formatter/json' variant="primary">Go</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{width: '18rem', height: '27.5rem'}}>
                        <Card.Img className="card-img-block" variant="top" src={StringCaseImage}/>
                        <Card.Body>
                            <Card.Title>String Case Conversion</Card.Title>
                            <Card.Text>
                                To convert String to its String Case.
                            </Card.Text>
                            <Button href='/converter/string' variant="primary">Go</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card style={{width: '18rem', height: '27.5rem'}}>
                        <Card.Img className="card-img-block" variant="top" src={Base64Image}/>
                        <Card.Body>
                            <Card.Title>Base64 Conversion</Card.Title>
                            <Card.Text>
                                Encode and Decode a String to its base64 / String Value.
                            </Card.Text>
                            <Button href='/converter/base64' variant="primary">Go</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{paddingTop: '5%'}}>
                <Col>
                    <Card style={{width: '18rem', height: '28.5rem'}}>
                        <Card.Img className="card-img-block" variant="top" src={PostmanToOpenApi}/>
                        <Card.Body>
                            <Card.Title>Postman To OpenApi Conversion</Card.Title>
                            <Card.Text>
                                Convert Postman Collection to Open Api Specification.
                            </Card.Text>
                            <Button href='/converter/postman-to-openapi' variant="primary">Go</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
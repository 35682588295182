import React, {useEffect} from 'react';
import './App.css';
import SideBar from "./components/common/SideBar";
import TopNavigation from "./components/common/TopNavigation";
import {PrivateRoute, PublicRoute} from "./services/Route";
import {Switch} from "react-router-dom";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Home from "./components/home/Home";
import StringConverter from "./components/stringconverter/StringConverter";
import FormatterJson from "./components/jsonformatter/FormatterJson";
import Base64 from "./components/base64/Base64";
import PostmanToOpenApi from "./components/postmanconvert/PostmanToOpenApi";

function App(props) {
    useEffect(() => {

    }, []);
  return (
      <div id="MainApp">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"MainApp"}/>
        <div id="page-wrap">
          <TopNavigation/>
          <div className="container">
            <React.StrictMode>
              <Switch>

                <PublicRoute
                    path="/login"
                    component={Login}
                />

                  <PublicRoute
                      exact
                      path="/"
                      component={Home}
                  />

                  <PublicRoute
                      exact
                      path="/converter/string"
                      component={StringConverter}
                  />

                  <PublicRoute
                      exact
                      path="/formatter/json"
                      component={FormatterJson}
                  />

                  <PublicRoute
                      exact
                      path="/converter/base64"
                      component={Base64}
                  />

                  <PublicRoute
                      exact
                      path="/converter/postman-to-openapi"
                      component={PostmanToOpenApi}
                  />

                <PrivateRoute
                    path="/logout"
                    component={Logout}
                />
              </Switch>
            </React.StrictMode>
          </div>
        </div>
      </div>
  );
}

export default App;

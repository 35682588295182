import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

export default function Base64(props) {

    const [state, setState] = useState({
        stringData: '',
        result: ''
    });

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state;
        formData[name] = value
        alterState({...formData})
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let renderBase44 = e => {
        try {
            //reset error
            alterState({result: ''});
            // Convert string to base64
            let bufferObj = Buffer.from(state.stringData, "utf8");
            alterState({result: bufferObj.toString("base64")});
        } catch (e) {
            console.log(e.message);
        }
    }

    let renderString = e => {
        try {
            //reset error
            alterState({result: ''});
            // Convert string to base64
            let bufferObj = Buffer.from(state.stringData, "base64");
            alterState({result: bufferObj.toString("utf8")});
        } catch (e) {
            console.log(e.message);
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Form.Group controlId="stringData">
                        <Form.Label>String Data</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name='stringData'
                            value={state.stringData}
                            onChange={handleUserInput}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" size="lg" block onClick={renderBase44}>
                        Base64 Encoding
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" size="lg" block onClick={renderString}>
                        Base64 Decoding
                    </Button>
                </Col>
            </Row>
            <br/>
            <br/>
            <Row>

                {state.result.length !== 0 ? (<Col><label>RESULT</label>
                    <Form.Group controlId="result">
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name='result'
                            value={state.result}
                            readOnly={true}
                        />
                    </Form.Group>
                </Col>) : ''}

            </Row>
        </div>
    );
}
import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import JSONFormatter from "json-formatter-js";
import {JsonFormatter} from 'react-json-formatter';
import './CSS/FormatterJson.css';

export default function FormatterJson(props) {

    const [state, setState] = useState({
        jsonData: '',
        jsonFormattedData: '',
        jsonFormattedObject: {},
        error: ''
    });

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state;
        formData[name] = value
        alterState({...formData})
    }

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let renderJson = e => {
        try {
            //reset error
            alterState({jsonFormattedData: '', error: '', jsonFormattedObject: {}});
            // object-result
            let jsonFormattedData = JSON.parse(state.jsonData);
            let formatter = new JSONFormatter(jsonFormattedData);
            // document.getElementById('object-result').innerHTML = '';
            // document.getElementById('object-result').appendChild(formatter.render());

            // text-result
            alterState({jsonFormattedData: state.jsonData, jsonFormattedObject: formatter.render(), error: ''});
        } catch (e) {
            alterState({jsonFormattedData: '', error: e.message, jsonFormattedObject: {}});
        }
    }

    const JsonStyle = {
        propertyStyle: {color: 'red'},
        stringStyle: {color: 'green'},
        numberStyle: {color: 'darkorange'}
    }

    return (
        <div>
            <Row>
                <Col>
                    <Form.Group controlId="jsonData">
                        <Form.Label>JSON DATA</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name='jsonData'
                            value={state.jsonData}
                            onChange={handleUserInput}
                        />
                    </Form.Group>
                    <br/>
                    <Button variant="primary" size="lg" block onClick={renderJson}>
                        Format JSON
                    </Button>
                </Col>
                {state.jsonFormattedData.length !== 0 ? (<Col><label>RESULT IN OBJECT</label>
                    <div id='object-result'><JsonObject renderData={state.jsonFormattedObject}/></div>
                </Col>) : ''}
            </Row>
            <br/>
            <br/>

            <Row className="text-result">
                {state.jsonFormattedData.length !== 0 ? (
                    <Col><label>RESULT IN STRING</label> <JsonFormatter json={state.jsonFormattedData} tabWith='10'
                                                                        JsonStyle={JsonStyle}/></Col>) : <div></div>}
                {state.error.length !== 0 ? (<Col><label>ERROR</label>
                    <div>{state.error}</div>
                </Col>) : <div></div>}
            </Row>

        </div>
    );
}

let JsonObject = function (props) {
    useEffect(() => {
        document.getElementById('obj').appendChild(props.renderData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<div id='obj'></div>);
}
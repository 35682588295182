import React, {useState} from "react";
import {Col, Form, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import stringcase from "stringcase";


export default function StringConverter(props) {

    const [state, setState] = useState({
        stringText: '',
        convertedText: '',
        convertTo: 0
    });

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state;
        formData[name] = value
        alterState({...formData})
    }

    let convertString = (funName) => {
        alterState({convertedText: stringcase[funName](state.stringText)});
    }

    return (
        <div>
            <div>
                <Row>
                    <Col>
                        <Form.Group controlId="stringText">
                            <Form.Label>STRING TEXT</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                name='stringText'
                                value={state.stringText}
                                onChange={handleUserInput}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButtonGroup name='convertTo' value={state.convertTo} onChange={convertString}>
                            <ToggleButton value={'camelcase'}>Camel</ToggleButton>
                            <ToggleButton value={'capitalcase'}>Capital</ToggleButton>
                            <ToggleButton value={'constcase'}>Constant</ToggleButton>
                            <ToggleButton value={'cramcase'}>Cram</ToggleButton>
                            <ToggleButton value={'decapitalcase'}>DeCapital</ToggleButton>
                            <ToggleButton value={'dotcase'}>Dot</ToggleButton>
                            <ToggleButton value={'enumcase'}>Enum</ToggleButton>
                            <ToggleButton value={'lowercase'}>Lower</ToggleButton>
                            <ToggleButton value={'pascalcase'}>Pascal</ToggleButton>
                            <ToggleButton value={'pathcase'}>Path</ToggleButton>
                            <ToggleButton value={'sentencecase'}>Sentence</ToggleButton>
                            <ToggleButton value={'snakecase'}>Snake</ToggleButton>
                            <ToggleButton value={'spacecase'}>Space</ToggleButton>
                            <ToggleButton value={'spinalcase'}>Spinal</ToggleButton>
                            <ToggleButton value={'titlecase'}>Title</ToggleButton>
                            <ToggleButton value={'trimcase'}>Trim</ToggleButton>
                            <ToggleButton value={'uppercase'}>Upper</ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col>
                        {state.convertedText.length !== 0 ? (<Col><label>CONVERTED STRING</label>
                            <Form.Group controlId="convertedText">
                                <Form.Label>STRING TEXT</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    name='convertedText'
                                    value={state.convertedText}
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Col>) : <div></div>}
                    </Col>
                </Row>
            </div>
        </div>
    );
}
import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import './CSS/FormatterJson.css';
import postmanToOpenApi from 'postman-to-openapi-module';

export default function PostmanToOpenApi(props) {

    const [state, setState] = useState({
        postmanCollection: '',
        openApiData: '',
        error: ''
    });

    let alterState = (key, value) => {
        let st = state;
        if (typeof key === "object") {
            st = {...state, ...key}
        } else if (value === null || value === undefined || typeof value === "object") {
            st[key] = value;
        } else {
            st[key] = value.constructor === String ? value.trim() : value;
        }
        setState({...st})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state;
        formData[name] = value
        alterState({...formData})
    }

    let convertToOpenApi = e => {
        try {
            //reset error
            alterState({openApiData: '', error: ''});
            let openApiData = postmanToOpenApi(
                state.postmanCollection
            );
            // text-result
            alterState({openApiData: openApiData, error: ''});
        } catch (e) {
            alterState({openApiData: '', error: "Not a Valid Postman JSON Data ["  + e.message + "]"});
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Form.Group controlId="postmanCollection">
                        <Form.Label>POSTMAN COLLECTIONS DATA</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name='postmanCollection'
                            value={state.postmanCollection}
                            onChange={handleUserInput}
                        />
                    </Form.Group>
                    <br/>
                    <Button variant="primary" size="lg" block onClick={convertToOpenApi}>
                        Convert
                    </Button>
                </Col>
            </Row>
            <br/>
            <br/>

            <Row className="text-result">
                {state.openApiData.length !== 0 ? (
                    <Col>
                        <Form.Group className="mb-3" controlId="openApiData">
                            <Form.Label>OPEN API FORMAT</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                name='openApiData'
                                value={state.openApiData}
                            />
                        </Form.Group>
                    </Col>) : <div></div>}
                {state.error.length !== 0 ? (<Col><label>ERROR</label>
                    <div>{state.error}</div>
                </Col>) : <div></div>}
            </Row>

        </div>
    );
}
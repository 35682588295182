import API from "./API";

class APIservices {
    login = async (formData) => {
        return await API.postData('/login', formData);
    }

    logout = async () => {
        return await API.postData('/logout', {});
    }
}

export default new APIservices();